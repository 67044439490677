import React from 'react';

import Category from 'components/help/category';

import { product } from 'data/helpArticles';

export default () => (
  <Category
    title="Product"
    articles={product}
    url="/help/product/"
    description="Find more informations about our product and learn how LiveSession works."
  />
);
