import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Link from 'components/ui/link';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';
import arrow from 'img/ui/arrow_black.svg';
import ArticlePreview from '../helpArticlePreview';
import Integration from '../integrationPreview';
import SearchBox from '../searchbox';
import * as Styled from './styled';

const HelpCategory = ({
  title,
  integrations = false,
  subtitle,
  staticContent = null,
  description,
  url,
  articles,
}) => {
  const renderArticles = () => {
    if (!articles || articles.length === 0) return false;

    const list = map(articles, (el) => (
      <Styled.CustomLi key={el.canonical}>
        <ArticlePreview key={el.link} {...el} />
      </Styled.CustomLi>
    ));
    return <ul style={{ padding: 0 }}>{list}</ul>;
  };

  const renderIntegrations = () => {
    if (!articles || articles.length === 0) return false;

    const list = map(articles, (el) => (
      <Styled.CustomLi key={el.canonical}>
        <Integration key={el.link} {...el} />
      </Styled.CustomLi>
    ));
    return <ul style={{ padding: 0 }}>{list}</ul>;
  };

  return (
    <Layout metaTitle={` ${title} | Help Center`} canonical={url} metaDescription={description}>
      <div className="container">
        <div className="row">
          <Styled.HeaderSection>
            <div className="breadcrumbs">
              <p>
                <Link href="/help/">Help</Link>
              </p>
              <img src={arrow} alt="arrow" title="Arrow" />
              <p>
                <strong>{title}</strong>
              </p>
            </div>
          </Styled.HeaderSection>
        </div>

        <div className="row justify-content-between">
          <Styled.ContentSection className="col-lg-8 col-sm-12">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            {staticContent}
            {!integrations && renderArticles()}
            {integrations && renderIntegrations()}
          </Styled.ContentSection>
          <SearchBox />
        </div>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default HelpCategory;

HelpCategory.propTypes = {
  title: PropTypes.string.isRequired,
  integrations: PropTypes.bool,
  subtitle: PropTypes.string,
  staticContent: PropTypes.node,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      metaTitle: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      canonical: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

HelpCategory.defaultProps = {
  integrations: false,
  staticContent: null,
  subtitle: null,
};
