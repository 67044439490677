import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const ArticlePreview = ({ metaTitle, metaDescription, canonical, logo, horizontal }) => {
  return (
    <Styled.Preview href={canonical}>
      <Styled.Card horizontal={horizontal}>
        <Styled.LogoWrapper style={{ backgroundImage: `url(${logo})` }} />
        <Styled.Title>{metaTitle}</Styled.Title>
        <Styled.Description>{metaDescription}</Styled.Description>
      </Styled.Card>
    </Styled.Preview>
  );
};

export default ArticlePreview;

ArticlePreview.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  logo: PropTypes.string,
  horizontal: PropTypes.bool,
};

ArticlePreview.defaultProps = {
  horizontal: false,
  logo: null,
};
