import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Title = styled.h2`
  font-size: 22px;
  color: #0a4ed6;
  font-weight: 900;
  margin-top: 16px;
  margin-bottom: 0;
  ${media.tablet} {
    font-size: 1rem;
    line-height: 28px;
  }
`;

export const Preview = styled.a`
  &:hover {
    text-decoration: none;
    ${Title} {
      text-decoration: underline;
    }
  }

  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const Card = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 48px 0;
  ${media.tablet} {
    padding: 30px 0;
  }
`;

export const Description = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  ${media.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 150px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  ${media.tablet} {
    width: 115px;
    height: 35px;
  }
`;
