import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { Card } from '../integrationPreview/styled';

export const HeaderSection = styled.section`
  width: 100%;
  padding: 0;
  max-width: 1200px;
  margin: 144px 0 56px;
  transform: translateX(5px);

  ${media.tablet} {
    display: none;
  }
  ${media.mobile} {
    margin-top: 91px;
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    & img {
      margin: 0 16px;
      ${media.mobile} {
        max-width: 8px;
      }
    }
    & p {
      margin: 0;
      color: #000;
      font-size: 14px;
      line-height: 24px;
      ${media.mobile} {
        line-height: normal;
      }
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        ${media.mobile} {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const ContentSection = styled.section`
  padding: 0 15px 0 0;
  ${media.tablet} {
    padding: 15px;
    margin-top: 120px;
  }
  h1 {
    margin-bottom: 8px;
    ${media.tablet} {
      font-size: 28px;
      line-height: 38px;
    }
  }
  h1 + p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    width: 90%;
    ${media.tablet} {
      font-size: 18px;
      line-height: 24px;
      margin-top: 24px;
    }
  }
`;

export const CustomLi = styled.li`
  border-top: 1px solid #e4e5e8;
  width: 90%;
  list-style: none;
  margin-bottom: 0;
  &:first-child {
    border-top: none;
    margin-top: 24px;
  }
  &:last-child ${Card} {
    padding: 48px 0 0 0;
    ${media.tablet} {
      padding: 30px 0 0 0;
    }
  }
  ${media.mobile} {
    width: 100%;
  }
`;
